import D1 from "./1.png";
import D2 from "./2.png";
import D3 from "./3.png";
import D4 from "./4.png";
import D5 from "./5.png";
import D6 from "./6.png";

const x = {
  1: D1,
  2: D2,
  3: D3,
  4: D4,
  5: D5,
  6: D6,
};

export default x;
