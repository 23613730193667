import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  createBrowserRouter,
  RouterProvider,
  Link,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Drawer from "./Drawer";
import Dice from "./Dice";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAH_LNn5TEpvrsFi2MGfHQGeb5quY2DRag",
  authDomain: "game-84b00.firebaseapp.com",
  projectId: "game-84b00",
  storageBucket: "game-84b00.appspot.com",
  messagingSenderId: "1048087527198",
  appId: "1:1048087527198:web:2129d0a15e428d9abb530f",
  measurementId: "G-5EERDY99TS",
  databaseURL:
    "https://game-84b00-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const database = getDatabase(app);

type Player = {
  name: string;
  id: string;
};

const getAndSetProfile = () => {
  const profile = localStorage.getItem("profile");
  if (profile) {
    return JSON.parse(profile) as Player;
  }
  const newProfile: Player = {
    name: "",
    id: Math.random().toString(16).slice(2),
  };
  localStorage.setItem("profile", JSON.stringify(newProfile));
  return newProfile;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <h1>Hello World</h1>
        <Link to="dice/sar">Dice</Link>
      </div>
    ),
  },
  {
    path: "dice/:gameId",
    element: <Dice />,
  },
]);

export default function ButtonAppBar() {
  const [input, setInput] = useState(getAndSetProfile().name);
  const [open, setOpen] = useState(getAndSetProfile().name === "");
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Drawer />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            game.jysim3.com
          </Typography>
          <Button color="inherit" onClick={() => setOpen(true)}>
            {getAndSetProfile().name}
          </Button>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Name</DialogTitle>
            <DialogContent>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={input}
                onChange={(event) => setInput(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  localStorage.setItem(
                    "profile",
                    JSON.stringify({
                      ...getAndSetProfile(),
                      name: input,
                    }),
                  );
                  window.location.reload();
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>
  <RouterProvider router={router} />
    </Box>
  );
}
