import { db } from "./App";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, onSnapshot, setDoc } from "firebase/firestore";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Grid, Paper } from "@mui/material";

import DiceImages from "./Dice/assets";

type Player = {
  name: string;
  id: string;
};

type DieFace = 1 | 2 | 3 | 4 | 5 | 6;

type Game = {
  name: string;
  players: Player[];
  state: "open" | "running" | "init" | "starting";
  dice: { [key: string]: DieFace[] };
};

const getAndSetProfile = () => {
  const profile = localStorage.getItem("profile");
  if (profile) {
    return JSON.parse(profile) as Player;
  }
  const newProfile: Player = {
    name: "new name",
    id: Math.random().toString(16).slice(2),
  };
  localStorage.setItem("profile", JSON.stringify(newProfile));
  return newProfile;
};

export default function DiceGame() {
  const { gameId } = useParams() as { gameId: string };

  const [state, setState] = useState<Game>({
    name: "",
    players: [],
    dice: {},
    state: "open",
  });
  const profile = getAndSetProfile();

  useEffect(() => {
    const profile = getAndSetProfile();
    const unsub = onSnapshot(doc(db, "game", gameId), (gameDoc) => {
      if (!gameDoc.exists()) {
        setDoc(doc(db, "game", gameId), {
          name: "dice",
          players: [],
          dice: {},
          state: "open",
        });
        return;
      }
      const gameData: Game = gameDoc.data() as Game;
      console.log(gameData);
      const me = gameData.players.find((player) => player.id === profile.id);
      if (!me || me.name !== profile.name) {
        const otherPlayers = gameData.players.filter(
          (player) => player.id !== profile.id,
        );
        setDoc(doc(db, "game", gameId), {
          ...gameData,
          players: [...otherPlayers, profile].sort((a, b) =>
            a.id.localeCompare(b.id),
          ),
        });
      }
      setState(gameData);
    });
    return unsub;
  }, [gameId]);

  const start = () => {
    setDoc(doc(db, "game", gameId), { ...state, state: "starting", dice: {} });
    setTimeout(() => {
      setState((currentState) => {
        if (currentState.state !== "starting") {
          return currentState;
        }
        setDoc(doc(db, "game", gameId), {
          ...currentState,
          players: currentState.players.filter(
            ({ id }) => currentState.dice[id],
          ),
          state: "running",
        });
        return currentState;
      });
    }, 5000);
  };

  useEffect(() => {
    if (state.state === "starting") {
      const profile = getAndSetProfile();
      console.log(state.dice, profile.id);
      if (state.dice[profile.id]) {
        return;
      }

      const dice = Object.values(state.dice);
      let newState: "running" | "starting" = state.state;
      if (dice.length === state.players.length - 1) {
        newState = "running";
      }

      console.log("set doc");
      setDoc(doc(db, "game", gameId), {
        ...state,
        state: newState,
        dice: {
          ...state.dice,
          [profile.id]: Array.from(Array(5).keys()).map(() =>
            getRandomInt(1, 6),
          ),
        },
      });
    }
  }, [state, state.state, gameId]);

  const open = () => {
    setDoc(doc(db, "game", gameId), { ...state, state: "open" });
  };

  console.log(state);

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h2" component="div" textAlign="center">
          Liar's dice
        </Typography>
      </Grid>
        <Typography variant="h4" component="div" textAlign="center">
          Room: {gameId}
        </Typography>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        xs={12}
      >
        {
          {
            open: (
              <Grid item>
                <BasicTable state={state} />
              </Grid>
            ),
            running: state.dice[profile.id] ? (
              state.dice[profile.id].map((die, index) => (
                <Grid item xs={2} key={index}>
                  <Paper>
                    <img
                      style={{ maxWidth: "100%" }}
                      alt={die.toString()}
                      src={DiceImages[die]}
                    />
                  </Paper>
                </Grid>
              ))
            ) : (
              <BasicTable state={state} />
            ),
            starting: <h1>Waiting for others... Please wait</h1>,
            init: null,
          }[state.state]
        }
      </Grid>
      <Grid item>
        {
          {
            open: <Button onClick={start}> Start</Button>,
            running: state.dice[profile.id] ? (
              <Button onClick={open}>Open</Button>
            ) : (
              <Button onClick={open}>Spectating... (force open)</Button>
            ),
            starting: <Button onClick={start}>Force restart</Button>,
            init: null,
          }[state.state]
        }
      </Grid>
    </Grid>
  );
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function BasicTable({ state }: { state: Game }) {
  return (
    <TableContainer component={Paper} sx={{ flex: 1, margin: "2px" }}>
      <Table size="small" aria-label="simple table" padding="checkbox">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {([1, 2, 3, 4, 5, 6] as DieFace[]).map((n) => (
              <TableCell align="right" key={n}>
                <img
                  style={{ maxWidth: "20px" }}
                  alt={n.toString()}
                  src={DiceImages[n]}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.players.map((player) => (
            <TableRow
              key={player.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ maxWidth: "20px", overflow: "hidden" }}
              >
                {player.name}
              </TableCell>
              {([1, 2, 3, 4, 5, 6] as DieFace[]).map((n) => (
                <TableCell align="right" key={n}>
                  {(state.dice[player.id] || [])
                    .filter((die) => die === n)
                    .map((die, index) => (
                      <img
                        key={index}
                        style={{ maxWidth: "20px" }}
                        alt={die.toString()}
                        src={DiceImages[die]}
                      />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
