import { useState, useEffect } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./App";

export default function TemporaryDrawer() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const [gameRooms, setGameRooms] = useState<{ name: string; id: string }[]>(
    [],
  );
  console.log(gameRooms);
  useEffect(() => {
    if (gameRooms && gameRooms.length > 0) {
      return;
    }
    const rooms = [] as { name: string; id: string }[];
    getDocs(collection(db, "game")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const room = doc.data();
        rooms.push({
          id: doc.id,
          name: room.name,
        });
      });
    });
    setGameRooms(rooms);
  }, [gameRooms]);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {gameRooms.map(({ id, name }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton
              onClick={() => {
                window.location.pathname = `${name}/${id}`;
              }}
            >
              <ListItemIcon>{<InboxIcon />}</ListItemIcon>
              <ListItemText primary={`${name} (${id})`} />
            </ListItemButton>
            <ListItemIcon
              onClick={(e) => {
                e.preventDefault();
                deleteDoc(doc(db, "game", id));
                setGameRooms([]);
              }}
            >
              {<DeleteIcon />}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["dice"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                window.location.pathname = `${text}/${(Math.random() + 1).toString(36).substring(7)}`;
              }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={`Start a ${text}`} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton onClick={() => {}}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Delete all room" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
